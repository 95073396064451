import clsx from 'clsx';
import React from 'react';
import { FieldError } from 'react-hook-form';

interface InputErrorLabelProps {
  error?: string | FieldError;
  className?: string;
}

export default function InpurErrorLabel({
  error,
  className,
}: InputErrorLabelProps) {
  if (!error) return null;

  return (
    <span
      className={clsx('rounded text-xs font-bold text-primary-500', className)}
    >
      {typeof error === 'string' ? error : error.message}
    </span>
  );
}
