import { Component, DragEventHandler, ReactNode } from 'react';

interface LayoutProps {
  children: ReactNode;
  extraClassNames: string;
  onDrop?: DragEventHandler<HTMLDivElement>;
  onDragOver?: DragEventHandler<HTMLDivElement>;
  onDragLeave?: DragEventHandler<HTMLDivElement>;
  onDragEnter?: DragEventHandler<HTMLDivElement>;
}

class ShadowedContainer extends Component<LayoutProps> {
  render() {
    const {
      children,
      extraClassNames,
      onDrop,
      onDragOver,
      onDragLeave,
      onDragEnter,
    } = this.props;
    return (
      <div
        className={`rounded-xl border border-stone-100 shadow-lg ${extraClassNames}`}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDragEnter={onDragEnter}
      >
        {children}
      </div>
    );
  }
}

export default ShadowedContainer;
