import React from 'react';

function EyeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='10.991'
      viewBox='0 0 18 10.991'
      {...props}
    >
      <g id='Eye' transform='translate(-2855.907 -2690.727)'>
        <path
          id='Path_30'
          data-name='Path 30'
          d='M2864.761,2693.719a2.374,2.374,0,1,0,2.394,2.374A2.387,2.387,0,0,0,2864.761,2693.719Z'
          fill='#bfbfbf'
        />
        <path
          id='Path_31'
          data-name='Path 31'
          d='M2873.357,2694.706a11.149,11.149,0,0,0-8.45-3.979h0a11.149,11.149,0,0,0-8.45,3.979,2.365,2.365,0,0,0,0,3.032,11.15,11.15,0,0,0,8.45,3.98h0a11.15,11.15,0,0,0,8.45-3.98A2.365,2.365,0,0,0,2873.357,2694.706Zm-8.6,5.765a4.378,4.378,0,1,1,4.415-4.378A4.4,4.4,0,0,1,2864.761,2700.471Z'
          fill='#bfbfbf'
        />
      </g>
    </svg>
  );
}

export default EyeIcon;
