import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes } from 'react';
import { IoClose } from 'react-icons/io5';

const Input = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & {
    icon?: React.ReactNode;
    showClearButton?: boolean;
    onClear?: () => void;
    wrapperClassName?: string;
  }
>(
  (
    { className, icon, showClearButton, onClear, wrapperClassName, ...rest },
    ref
  ) => {
    return (
      <div className={clsx('relative w-full', wrapperClassName)}>
        {icon && (
          <div className='pointer-events-none absolute left-2 top-1/2 -translate-y-1/2'>
            {icon}
          </div>
        )}
        <input
          className={clsx(
            'form-input h-9 w-full rounded-md border border-stone-300 bg-white shadow-sm outline-none placeholder:text-stone-400 focus:border-stone-400 focus:ring-0 disabled:bg-stone-100 disabled:text-stone-400 dark:bg-stone-900 dark:placeholder-stone-400',
            Boolean(icon) && 'pl-9',
            className
          )}
          {...rest}
          ref={ref}
        />
        <button
          className={clsx(
            'pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 opacity-0 transition duration-500',
            showClearButton && 'pointer-events-auto opacity-100'
          )}
          onClick={onClear}
        >
          <IoClose />
        </button>
      </div>
    );
  }
);

Input.displayName = 'Input';
export default Input;
