import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes, useState } from 'react';

import Input from './Input';
import EyeIcon from '../Icons/EyeIcon';
import EyeOffIcon from '../Icons/EyeOffIcon';

const PasswordInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(({ className, ...rest }, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='relative w-full'>
      <Input
        className={clsx(className)}
        type={showPassword ? 'text' : 'password'}
        {...rest}
        ref={ref}
      />
      <div
        className='absolute inset-y-0 right-0 flex items-center pr-3'
        onClick={handleClickShowPassword}
      >
        {showPassword ? <EyeIcon /> : <EyeOffIcon />}
      </div>
    </div>
  );
});

PasswordInput.displayName = 'PasswordInput';
export default PasswordInput;
