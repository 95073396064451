import React from 'react';

function EyeOffIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='13.51'
      viewBox='0 0 18 13.51'
      {...props}
    >
      <g id='Eye' transform='translate(-2855.907 -2689.738)'>
        <g id='Eye-2' data-name='Eye'>
          <path
            id='Path_30'
            data-name='Path 30'
            d='M2864.761,2693.719a2.374,2.374,0,1,0,2.394,2.374A2.387,2.387,0,0,0,2864.761,2693.719Z'
            fill='#bfbfbf'
          />
          <path
            id='Path_31'
            data-name='Path 31'
            d='M2873.357,2694.706a11.149,11.149,0,0,0-8.45-3.979h0a11.149,11.149,0,0,0-8.45,3.979,2.365,2.365,0,0,0,0,3.032,11.15,11.15,0,0,0,8.45,3.98h0a11.15,11.15,0,0,0,8.45-3.98A2.365,2.365,0,0,0,2873.357,2694.706Zm-8.6,5.765a4.378,4.378,0,1,1,4.415-4.378A4.4,4.4,0,0,1,2864.761,2700.471Z'
            fill='#bfbfbf'
          />
        </g>
        <line
          id='Line_6'
          data-name='Line 6'
          x2='12.909'
          y2='12.8'
          transform='translate(2858.711 2690.093)'
          fill='#bfbfbf'
          stroke='#bfbfbf'
          strokeWidth='1'
        />
      </g>
    </svg>
  );
}

export default EyeOffIcon;
