
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useFormWithSchemaBuilder } from '@/hooks/useFormWithSchemaBuilder';

import Button from '@/components/buttons/Button';
import LandingPageLayout from '@/components/layouts/LandingPageLayout';
import CustomLink from '@/components/links/CustomLink';
import Seo from '@/components/Seo';
import ShadowedContainer from '@/components/ShadowedContainer/ShadowedContainer';
import ErrorBox from '@/components/ui/ErrorBox/ErrorBox';
import InputErrorLabel from '@/components/ui/InputErrorLabel/InputErrorLabel';
import Input from '@/components/ui/Inputs/Input';
import PasswordInput from '@/components/ui/Inputs/PasswordInput';
import Logo from '@/components/ui/Logo/Logo';

import { trackEvent } from '@/utils/firebase';

import { SecuredBasePageProps } from '@/types';

interface FormValues {
  email: string;
  password: string;
}

export default function LoginPage({ t }: SecuredBasePageProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const router = useRouter();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormWithSchemaBuilder((yup) =>
    yup.object({
      email: yup.string().email().required('Required'),
      password: yup.string().required('Required'),
    })
  );

  //this obejct status code of response
  const statusCodeReponse = {
    '403': 'Your user type cannot access the Dashboard',
    '500': 'Your credentials are invalid',
  };

  // This handles the account deleted redirect case
  useEffect(() => {
    const { referenceId } = router.query;

    if (referenceId) {
      toast.success(t('delete-account-issued', { referenceId }), {
        autoClose: false,
      });
    }
  }, [router, t]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    setIsLoading(true);
    signIn('username-login', {
      username: data.email,
      password: data.password,
      redirect: false,
    })
      .then((res) => {
        trackEvent('login');
        if (res && res.ok) {
          router.push('/dashboard');
        } else {
          const status =
            statusCodeReponse[res?.error as keyof typeof statusCodeReponse];
          setError(status);
        }
      })
      .catch((e) => {
        const message = e.response?.data?.message || 'Something went wrong';
        setError(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LandingPageLayout hideSearchBox>
      <Seo templateTitle='Login' />
      <ShadowedContainer extraClassNames='mx-auto mt-4 px-10 py-8 w-full max-w-lg'>
        <div className='flex justify-center'>
          <Logo className='h-5' filled />
        </div>

        <form
          className='grid grid-cols-1 space-y-4 pb-4 pt-12'
          onSubmit={handleSubmit(onSubmit)}
        >
          <ErrorBox error={error} />
          <div className='flex flex-col'>
            <label className='mb-2 font-normal'>{t('email')}</label>
            <Input
              type='text'
              {...register('email')}
              data-testid='EmailInput'
            />
            <InputErrorLabel error={errors.email?.message} />
          </div>
          <div className='flex flex-col'>
            <label className='mb-2 font-normal'>{t('password')}</label>
            <PasswordInput
              data-testid='PasswordInput'
              {...register('password')}
            />
            <InputErrorLabel error={errors.password?.message} />
          </div>
          <div className='flex items-center justify-end py-2'>
            <CustomLink className='text-sm' href='/forgot-password'>
              {t('forgot')}
            </CustomLink>
          </div>
          <Button
            isLoading={isLoading}
            className='!mb-2 flex w-full justify-center !bg-primary-500 !py-2 !font-medium'
            type='submit'
            data-testid='SigninButton'
          >
            {t('signin')}
          </Button>
        </form>
      </ShadowedContainer>
    </LandingPageLayout>
  );
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  