import React from 'react';

export default function ErrorBox({ error }: { error?: string }) {
  if (!error) return null;
  return (
    <div className='rounded border border-primary-500 p-4 text-sm font-bold'>
      {error}
    </div>
  );
}
